import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { createMetaManager, plugin as metaPlugin } from "vue-meta";
import "vue-fast-marquee/style.css";
import Marquee from "vue-fast-marquee";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";
import VueGtag from "vue-gtag";

loadFonts();

const appVue = createApp(App);
appVue.component("vue-cookie-accept-decline", VueCookieAcceptDecline);

appVue
  .use(
    VueGtag,
    {
      config: { id: "G-FLS14S7S5Y" },
      appName: "WORK-O.COM datastream",
      pageTrackerScreenviewEnabled: true,
      enabled: false,
      bootstrap: true,
      onError(error) {
        console.log(error);
      },
    },
    router
  )
  .use(router)
  .use(vuetify)
  .use(createMetaManager())
  .use(Marquee)
  .use(metaPlugin);

appVue.mount("#app");
